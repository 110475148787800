/* nunito-sans-200 - latin */
@font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url('nunito-sans-v5-latin-200.eot'); /* IE9 Compat Modes */
    src: local('Nunito Sans ExtraLight'), local('NunitoSans-ExtraLight'),
    url('nunito-sans-v5-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('nunito-sans-v5-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
    url('nunito-sans-v5-latin-200.woff') format('woff'), /* Modern Browsers */
    url('nunito-sans-v5-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
    url('nunito-sans-v5-latin-200.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-200italic - latin */
@font-face {
    font-family: 'Nunito Sans';
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url('nunito-sans-v5-latin-200italic.eot'); /* IE9 Compat Modes */
    src: local('Nunito Sans ExtraLight Italic'), local('NunitoSans-ExtraLightItalic'),
    url('nunito-sans-v5-latin-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('nunito-sans-v5-latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('nunito-sans-v5-latin-200italic.woff') format('woff'), /* Modern Browsers */
    url('nunito-sans-v5-latin-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('nunito-sans-v5-latin-200italic.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-300 - latin */
@font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('nunito-sans-v5-latin-300.eot'); /* IE9 Compat Modes */
    src: local('Nunito Sans Light'), local('NunitoSans-Light'),
    url('nunito-sans-v5-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('nunito-sans-v5-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('nunito-sans-v5-latin-300.woff') format('woff'), /* Modern Browsers */
    url('nunito-sans-v5-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('nunito-sans-v5-latin-300.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-300italic - latin */
@font-face {
    font-family: 'Nunito Sans';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('nunito-sans-v5-latin-300italic.eot'); /* IE9 Compat Modes */
    src: local('Nunito Sans Light Italic'), local('NunitoSans-LightItalic'),
    url('nunito-sans-v5-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('nunito-sans-v5-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('nunito-sans-v5-latin-300italic.woff') format('woff'), /* Modern Browsers */
    url('nunito-sans-v5-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('nunito-sans-v5-latin-300italic.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-regular - latin */
@font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('nunito-sans-v5-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Nunito Sans Regular'), local('NunitoSans-Regular'),
    url('nunito-sans-v5-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('nunito-sans-v5-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('nunito-sans-v5-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('nunito-sans-v5-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('nunito-sans-v5-latin-regular.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-italic - latin */
@font-face {
    font-family: 'Nunito Sans';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('nunito-sans-v5-latin-italic.eot'); /* IE9 Compat Modes */
    src: local('Nunito Sans Italic'), local('NunitoSans-Italic'),
    url('nunito-sans-v5-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('nunito-sans-v5-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('nunito-sans-v5-latin-italic.woff') format('woff'), /* Modern Browsers */
    url('nunito-sans-v5-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('nunito-sans-v5-latin-italic.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-600italic - latin */
@font-face {
    font-family: 'Nunito Sans';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url('nunito-sans-v5-latin-600italic.eot'); /* IE9 Compat Modes */
    src: local('Nunito Sans SemiBold Italic'), local('NunitoSans-SemiBoldItalic'),
    url('nunito-sans-v5-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('nunito-sans-v5-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('nunito-sans-v5-latin-600italic.woff') format('woff'), /* Modern Browsers */
    url('nunito-sans-v5-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('nunito-sans-v5-latin-600italic.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-600 - latin */
@font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('nunito-sans-v5-latin-600.eot'); /* IE9 Compat Modes */
    src: local('Nunito Sans SemiBold'), local('NunitoSans-SemiBold'),
    url('nunito-sans-v5-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('nunito-sans-v5-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('nunito-sans-v5-latin-600.woff') format('woff'), /* Modern Browsers */
    url('nunito-sans-v5-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('nunito-sans-v5-latin-600.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-700 - latin */
@font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('nunito-sans-v5-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Nunito Sans Bold'), local('NunitoSans-Bold'),
    url('nunito-sans-v5-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('nunito-sans-v5-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('nunito-sans-v5-latin-700.woff') format('woff'), /* Modern Browsers */
    url('nunito-sans-v5-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('nunito-sans-v5-latin-700.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-800 - latin */
@font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('nunito-sans-v5-latin-800.eot'); /* IE9 Compat Modes */
    src: local('Nunito Sans ExtraBold'), local('NunitoSans-ExtraBold'),
    url('nunito-sans-v5-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('nunito-sans-v5-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
    url('nunito-sans-v5-latin-800.woff') format('woff'), /* Modern Browsers */
    url('nunito-sans-v5-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
    url('nunito-sans-v5-latin-800.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-700italic - latin */
@font-face {
    font-family: 'Nunito Sans';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('nunito-sans-v5-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local('Nunito Sans Bold Italic'), local('NunitoSans-BoldItalic'),
    url('nunito-sans-v5-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('nunito-sans-v5-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('nunito-sans-v5-latin-700italic.woff') format('woff'), /* Modern Browsers */
    url('nunito-sans-v5-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('nunito-sans-v5-latin-700italic.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-800italic - latin */
@font-face {
    font-family: 'Nunito Sans';
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url('nunito-sans-v5-latin-800italic.eot'); /* IE9 Compat Modes */
    src: local('Nunito Sans ExtraBold Italic'), local('NunitoSans-ExtraBoldItalic'),
    url('nunito-sans-v5-latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('nunito-sans-v5-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('nunito-sans-v5-latin-800italic.woff') format('woff'), /* Modern Browsers */
    url('nunito-sans-v5-latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('nunito-sans-v5-latin-800italic.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-900 - latin */
@font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('nunito-sans-v5-latin-900.eot'); /* IE9 Compat Modes */
    src: local('Nunito Sans Black'), local('NunitoSans-Black'),
    url('nunito-sans-v5-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('nunito-sans-v5-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
    url('nunito-sans-v5-latin-900.woff') format('woff'), /* Modern Browsers */
    url('nunito-sans-v5-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
    url('nunito-sans-v5-latin-900.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-900italic - latin */
@font-face {
    font-family: 'Nunito Sans';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url('nunito-sans-v5-latin-900italic.eot'); /* IE9 Compat Modes */
    src: local('Nunito Sans Black Italic'), local('NunitoSans-BlackItalic'),
    url('nunito-sans-v5-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('nunito-sans-v5-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('nunito-sans-v5-latin-900italic.woff') format('woff'), /* Modern Browsers */
    url('nunito-sans-v5-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('nunito-sans-v5-latin-900italic.svg#NunitoSans') format('svg'); /* Legacy iOS */
}