.Error {
    width: 100%;
    height: 100%;
    background-image: radial-gradient(#004867, #004766, #013B55);
    color: white;
}

.ErrorMessage {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-top: 25vh
}

.ErrorMessage > div {
    justify-content: center;
}