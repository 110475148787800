.tippy-root {
    font-family: 'Nunito Sans', sans-serif;
}

.tippy-box[data-theme~='regular'] {
    background-color: white;
    color: #002E41;
    box-shadow: 0 0 10px rgba(0,0,0,.3);
}

.tippy-box[data-theme~='regular'] .tippy-arrow::before {
    color: white;
}

.tippy-arrow::before {
    transform: scale(1.5);
}

.tippy-content {
    padding: 0 !important;
}

.tippy-box {
    max-width: unset !important;
    font-size: unset !important;
}
