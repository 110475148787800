.TwoFAQRCode {
  margin: 1.25rem 0 2.75rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CantScan {
  appearance: none;
  border: none;
  background: none;
  padding: 0;
  font: inherit;
  color: inherit;
  text-decoration: underline;
}
.CantScan:hover {
  color: #0086BE;
}

.TwoFASetupContent {
  height: 450px;
}

.TwoFAModalBody h1 {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0 0 1.5rem;
}

.TwoFAModalBody h3 {
  font-size: 1rem;
  margin: 0 0 0.5rem 0;
  font-weight: 600;
}

.TwoFAModalBody p, .TwoFAModalBody h1, .CantScan {
  margin: 0 0 1.5em 0;
}

.TwoFAModalBody p:last-child, .CantScan:last-child {
  margin: 0;
}

.TwoFAModalBody .SetupKey {
  background: #F5F5F5;
  border-radius: 2px;
  margin: 0 0 1.5em 0;
  padding: 0.67rem 1.33rem;
}
.TwoFAModalBody .SetupKey h3 {
  margin: 0;
  font-weight: 600;
}
.TwoFAModalBody .SetupKey p {
  margin: 0.2rem -0.5ch 0;
  font: 1rem ui-monospace, Menlo, Monaco, "Cascadia Mono", Consolas, "Roboto Mono", monospace;
  text-transform: lowercase;
  user-select: all;
}
.TwoFAModalBody .SetupKey p span {
  margin: 0 0.5ch 0 0.5ch;
}

/*.VerifyCode {*/
/*  margin: auto;*/
/*  width: calc(1rem + 2px + 9ch);*/
/*}*/

.VerifyCode {
  display: flex;
  justify-content: center;
}
.VerifyCode :global(.a-reactPinField__input) {
  font-family: inherit;
  border-radius: 2px;
  box-shadow: none; /* Override Firefox invalid styling */
  text-align: center;
  font-size: 2rem;
  padding: 0.75rem 1rem;
  font-variant-numeric: tabular-nums;
  width: 1ch;
  box-sizing: content-box;
  background: #F5F5F5;
  border: 1px solid #DDDDDD;
}
.VerifyCode :global(.a-reactPinField__input) + :global(.a-reactPinField__input) {
  margin-left: 1rem;
}
.VerifyCode :global(.a-reactPinField__input) + :global(.a-reactPinField__input):nth-of-type(4) {
  margin-left: 3rem;
}
.VerifyCode :global(.a-reactPinField__input):focus {
  outline: none;
  border-color: #00686B;
  /*border-color: #02898E;*/
}

@keyframes invalid-code {
  0% { transform: translateX(  0  ); }
  8% { transform: translateX(-10px); }
  25% { transform: translateX( 10px); }
  41% { transform: translateX(-10px); }
  58% { transform: translateX( 10px); }
  75% { transform: translateX( -5px); }
  92% { transform: translateX(  5px); }
  100% { transform: translateX(  0  ); }
}

.PrintIFrame, .CopyTextArea {
  border: none;
  outline: none;
  width: 1px;
  height: 1px;
  resize: none;
  padding: 0;
  margin: 0 -1px -1px 0;
  opacity: 0.01;
}

.Codes {
  font: 1.1rem ui-monospace, Menlo, Monaco, "Cascadia Mono", Consolas, "Roboto Mono", monospace;
  margin: 0 0 1.5em 0;
  color: #066C98;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.Codes.CoolGard {
  color: #00686B;
}
.Codes div {
  margin: 0.5rem 3rem;
}

.CodeButtons {
  display: flex;
}
.CodeButtons button {
  flex: 1 1 0;
}
.CodeButtons button:not(:first-of-type) {
  margin-left: 1rem;
}

.ListActionButton {
  width: 18rem;
  align-self: flex-end;
}

.Toast {
  margin: 1rem 1rem 0.3rem 1rem;
}
