.CheckIn {
  flex: auto;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.CheckInHeader {
  flex: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 18px 0;
  margin: 0 18px;
  min-height: 72px;
  border-bottom: 1px solid #ededed;
}

.CheckInHeaderPulse {
  padding: 0;
}

.CheckInHeaderBack {
  flex: none;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  appearance: none;
  border: none;
  outline: none;
  background: none;
  margin: -18px auto -18px -18px;
  padding: 21px 18px 15px 18px;
  font: inherit;
  color: inherit;
  font-size: 19px;
  font-weight: 600;
  transition: opacity 0.4s;
  will-change: opacity;
  opacity: 0;
}
.CheckInHeader.back .CheckInHeaderBack {
  opacity: 1;
}
.CheckInHeaderBackIcon {
  flex: none;
  margin: 0 10px 2px 0;
  font-size: 22px;
}

.CheckInHeaderLogo {
  flex: none;
  width: 147px;
  height: 24px;
  position: relative;
  transition: transform 0.4s;
  will-change: transform;
  transform: translateX(calc(-100vw + 36px + 147px));
}

.CheckInHeaderPulseLogo {
  height: 30px;
  width: 110px;
  transform: translateX(calc(-100vw + 36px + 110px));
}

.CheckInHeader.back .CheckInHeaderLogo {
  transform: translateX(0);
}

.CheckInBanner {
  flex: none;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 15px 0;
  margin: 0 18px;
  line-height: 1.2;
  border-bottom: 1px solid #ededed;
}
.CheckInBanner h1,
.CheckInBanner h2 {
  margin: 0;
  font: inherit;
}
.CheckInBanner h1 {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 7px;
}
.CheckInBanner h2 {
  font-size: 21px;
}

.CheckInMenu {
  flex: none;
  display: flex;
  line-height: 1.2;
  flex-direction: row;
  margin: 0 18px;
  border-bottom: 1px solid #ededed;
}

.CheckInMenu > ol {
  margin: 0 -18px;
  padding: 0;
  flex: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.CheckInMenu > ol > li {
  display: flex;
}
.CheckInMenu > ol > li.selected {
  font-weight: 600;
}
.CheckInMenu > ol > li > button {
  appearance: none;
  border: none;
  outline: none;
  background: none;
  font: inherit;
  color: inherit;
  padding: 15px 18px 15px 0px;
}
.CheckInMenu > ol > li:first-of-type > button {
  padding-left: 18px;
}
.CheckInMenu > ol > li:last-of-type > button {
  padding-right: 27px;
}
.CheckInMenu .Check {
  position: relative; /* Safari hack to let it overflow the button */
  width: 16px;
  height: 16px;
  margin-left: 2px;
  margin-right: -18px;
  color: #00686B;
}

.CheckInForm, .QuestionsForm, .CheckOutForm {
  width: 100%;
  flex: auto;
  display: flex;
  flex-direction: column;
}

.CheckInForm, .CheckOutForm {
  padding-top: 18px;
}

.CheckInInput {
  margin: 0 18px 16px 18px;
  flex: none;
}

.CheckInCodeInput {
  flex: none;
  display: flex;
  flex-direction: row;
  margin: 0 18px;
  padding: 16px 0;
  border-bottom: 1px solid #ededed;
  will-change: transform;
}
.CheckInCodeInput input {
  flex: none;
  width: 12.9%;
  margin-right: 3.2%;
  font: inherit;
  color: inherit;
  background: transparent;
  font-size: 6.7vw;
  text-align: center;
  border-radius: 2px;
  border: 1px solid #D1D1D1;
  padding: 2.7% 0;
  outline: none;
}
.CheckInCodeInput input:disabled {
  background: #f5f5f5;
}
.CheckInCodeInput input:focus {
  border-color: #09F;
}
.CheckInCodeInput input:nth-of-type(4) {
  margin-left: auto;
}
.CheckInCodeInput input:last-of-type {
  margin-right: 0;
}

.MainInstruction {
  text-align: left;
  margin: 0 18px;
  padding: 20px 0;
  border-bottom: 1px solid #ededed;
}
p.MainInstruction, .MainInstruction h1 {
  font-size: 23px;
  line-height: 1.4;
  font-weight: bold;
}
.MainInstruction h1 {
  margin: 0;
}
.MainInstruction p {
  margin: 5px 0 0 0;
}

.CodeStatus {
  text-align: center;
  margin: 0 18px;
  padding: 20px 0;
  line-height: 1.4;
  font-size: 18px;
  font-weight: bold;
}

.CodeStatusSpinner {
  margin-bottom: 9px;
}

.RetryButton {
  align-self: center;
}

.CheckInButtonBox {
  flex: none;
  display: flex;
  flex-direction: column;
  margin: auto 0 0 0;
  padding: 18px;
  background: #f5f5f5;
  border-top: 1px solid #ededed;
}

.CheckInQuestion {
  flex: none;
  display: flex;
  flex-direction: column;
  margin: 0 18px;
  padding: 20px 0;
  border-bottom: 1px solid #ededed;
}

.CheckInQuestion > p,
.CheckInQuestion > .QuestionText {
  font: inherit;
  text-align: left;
  font-weight: 600;
  line-height: 1.2;
  margin: 0 0 18px 0;
}

.CheckInQuestion .Passed,
.CheckInQuestion .Failed {
  width: 16px;
  height: 16px;
  margin-left: 2px;
  margin-right: -18px;
}
.CheckInQuestion .Passed {
  color: #00686B;
}
.CheckInQuestion .Failed {
  color: #bd2130;
}

.CheckInQuestion .RadioButtons {
  flex: none;
  display: flex;
  flex-direction: row;
}

.CheckInQuestion .CheckBoxes {
  flex: none;
  display: flex;
  flex-direction: column;
}

.CheckInAnswer {
  line-height: 1.2;
  padding: 9px;
  flex: 0px 1 1;
}
.CheckInAnswer ~ .CheckInAnswer {
  margin-left: 18px;
}

@keyframes invalid-code {
    0% { transform: translateX(  0  ); }
    8% { transform: translateX(-10px); }
   25% { transform: translateX( 10px); }
   41% { transform: translateX(-10px); }
   58% { transform: translateX( 10px); }
   75% { transform: translateX( -5px); }
   92% { transform: translateX(  5px); }
  100% { transform: translateX(  0  ); }
}

.PulseBanner {
  margin: auto 18px 0 18px;
  padding: 18px 0;
  display: flex;
  flex: none;
  border-top: 1px solid #ededed;
  align-items: center;
  min-height: 72px;
  font-size: 14px;
}

.PoweredBy {
  margin: -18px -18px -18px auto;
  padding: 18px;
  display: flex;
  align-items: center;
  align-self: stretch;
}
.PoweredBy svg {
  opacity: 0.4;
  margin: -18px 6px;
}

.StatusIcon {
  font-size: 5rem;
  align-self: center;
  margin: 35px 0 4px 0;
  color: #00686B;
  flex: none;
}

.CompleteIcon {
  color: #47B04B;
}

.FailureIcon {
  color: #FF4545;
}

.StatusBanner {
  padding: 18px 0;
  margin: 0 18px;
  border-bottom: 1px solid #ededed;
  flex: none;
}

.StatusBanner h1 {
  margin: 0 0 5px 0;
  font-size: 23px;
  line-height: 1.4;
  font-weight: bold;
}

.StatusBanner h2 {
  color: #012E41;
  margin: 5px 0;
  font-size: 23px;
  line-height: 1.4;
  font-weight: normal;
}

.StatusBanner p {
  margin: 0;
}

.StatusHint {
  padding: 18px 0;
  margin: 0 18px;
  border-bottom: 1px solid #ededed;
  text-align: left;
}
.StatusHint:last-of-type {
  border-bottom: none;
}

.StatusHint h1 {
  margin: 5px 0 10px 0;
  font-size: 21px;
  line-height: 1.4;
  font-weight: bold;
}

.StatusHint p {
  margin: 10px 0;
  font-size: 17px;
  line-height: 1.4;
}

.StatusHint.StatusHintSmall h1 {
  font-size: 15px;
}
.StatusHint.StatusHintSmall p {
  font-size: 15px;
}

.CompleteInfo {
  padding: 18px 0;
  margin: 0 18px;
  border-bottom: 1px solid #ededed;
  display: block;
  font-size: 18px;
  line-height: 1.8;
  flex: none;
}

.CompleteInfo dt {
  margin: 0 4px 0 0;
  float: left;
  clear: both;
  font-weight: bold;
}

.CompleteInfo dd {
  margin: 0;
  float: right;
  clear: right;
}

.MultiSelectAnswer {
  padding: .5rem .75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  min-height: 2.5rem;
  border: 2px solid #e5e5e5;

  margin-bottom: 0.5rem;
}

.MultiSelectAnswer:last-of-type {
  margin-bottom: 0;
}

.MultiSelectAnswerInput {
  -webkit-appearance: none;
  appearance: none;
  display: block;
  width: 13px;
  height: 13px;
  opacity: 0.00001;
  margin: 0 -13px -13px 0;
}

.MultiSelectAnswerInput:checked + .MultiSelectAnswer {
  border-color: #0099ff;
  background: #f5f5f5;
}
.MultiSelectAnswerInput:checked + .MultiSelectAnswer.Error {
  border-color: #bd2130;
}

.MultiSelectAnswer::before {
  display: block;
  content: ' ';
  width: 18px;
  background: #e5e5e5;
  height: 18px;
  margin-right: auto;
  border-radius: 2px;
  flex: none;
}
.MultiSelectAnswerInput:checked + .MultiSelectAnswer::before {
  background: center / 16px 16px no-repeat url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z" fill="%23004867"/></svg>'), #e5e5e5;
}
.MultiSelectAnswerInput:checked + .MultiSelectAnswer.Error::before {
  background: center / 16px 16px no-repeat url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" fill="%23bd2130"/></svg>'), #e5e5e5;
}
.MultiSelectAnswer::after {
  display: block;
  content: ' ';
  width: 18px;
  margin-left: auto;
  flex: 0 1 auto;
}

.SelectQuestionInput, .FreeTextQuestionInput {
  margin: 0;
}

.SelectFailed {
  position: absolute;
  right: 4.5rem;
  top: 0;
  bottom: 0;
}
