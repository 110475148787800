.PageHeader {
    text-align: left;
    font-size: 1.25rem;
    /*box-shadow: 0 0.25rem 0.75rem rgba(0,0,0,.1);*/
    border-bottom: 1px solid #DDDDDD;

    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-height: 3.7rem;
    flex-shrink: 0;
}

.BackButton {
    color: #166B8F;
}

.PageTitle {
    font-weight: 600;
    margin-bottom: -1px;
    color: #002E41;
    padding: 1rem;
    display: inline-block;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.Icon {
    padding-right: 0;
    padding-left: 1rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.NoBack {
    padding-left: 0;
}

.Flex {
    flex: 1 1 auto;
}
