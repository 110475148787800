.RadioInput {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
.RadioInputButton {
  display: block;
  width: 13px;
  height: 13px;
  opacity: 0.00001;
  margin: 0 -13px -13px 0;
}

.RadioLabel {

}

.RadioLabelButton {
  padding: .5rem .75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  height: 2.5rem;
  border: 2px solid #e5e5e5;
}

.RadioInput:checked + .RadioLabelButton {
  border-color: #0099ff;
  background: #f5f5f5;
}
.RadioInput:checked + .RadioLabelButton.Primary {
}
.RadioInput:checked + .RadioLabelButton.Danger {
  border-color: #bd2130;
}
