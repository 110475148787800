.SideBar {
  width: 3.7em;
  height: 100%;
  /*background: #004867 0% 0% no-repeat padding-box;*/
  /*background: #00686B 0% 0% no-repeat padding-box;*/
  /*margin: 5px;*/
  /*align-items: center;*/
  /*position: relative;*/
  z-index: 10002;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
}

.SideBar .Logo {
  width: 100%;
  height: 3.7em;
  /*background-color: #003D58;*/
  /*background-color: #005457;*/

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #FFFFFF2E;
}

.SideBar .Logo .Icon {
  color: #FFFFFF;
  height: 100%;
  padding: .5rem;
}

.SideBar .Logo .PulseIcon {
  color: #FFFFFF;
  height: 100%;
  padding: .7rem;
}

.SideBar .Menu {
  margin-top: 0.25rem;
  width: 100%;
  /*height: 80%;*/

  /*background-color: #00686B;*/
  /*background-color: #004867;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 2;
}

.SideBar .Config {
  width: 100%;
  display: flex;
  padding-top:0.25rem;
  padding-bottom:0.25rem;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.MenuItemContainer {
  height: 4rem;
}

.SideBar .Separator {
  height: 0.5px;
  margin: .25rem;
  background-color: #FFFFFF2E;
  width: 75%;
}

.SideBar .ConfigSeparator {
  height: 0.5px;
  margin-top:0.25rem;
  margin-bottom:0.25rem;
  background-color: #FFFFFF2E;
  width: 75%;
}
