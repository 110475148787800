
.TitleBar {
    width: 100%;
    max-height: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    background: white;
    border-bottom: 1px solid #DDDDDD;
    /*z-index: -99999;*/
margin-top:3.6875rem;
}

.Inside {
    height: 3rem;
    width: 100%;
    align-items: center;
    text-align: left;
    flex-direction: row;
    display: flex;
}

.Icon {
    margin-left: 0.5rem;
    color: #1A7196;
    font-size: 1.3rem;

}
.IconSolid {
    margin-left: 1rem;
    color: #1A7196;
    font-size: 1rem;

}

.IconExit {
    margin-left: 1rem;
    margin-right: 1rem;
    color: #1A7196;
    font-size: 1.3rem;

}

.UserSelected {
    flex: auto;
    font-weight: 600;
    font-size: 1.25rem;
    margin-left: 3rem;
    color: #002E41;
}


.block {
    padding-left: 1rem;
    height: 100%;
    display: flex;
    /*justify-content: center;*/
    align-items: center;
    flex: auto;
    border-left: 1px solid #DDDDDD;
    margin-right: 2rem;
    font-size: 1rem;
    color: #002E41;
}

.initial {
    padding-left: 1rem;
}

.Exit {
    margin-left: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: auto;
    flex-grow: 0;
    border-left: 1px solid #DDDDDD;
    font-weight: 600;
    color: #002E41;
}

.container {
    display: block;
    position: relative;
    padding-left: 2.1875rem;
    margin-bottom: 1.2rem;
    cursor: pointer;
    font-size: 0.875rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.3125rem;
    width: 1.3125rem;
    border: 1px solid #DCDCDC;
    background-color: #F7F7F7;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
    /*background-color: #004867;*/
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 0.5rem;
    top: 0.25rem;
    width: 0.3125rem;
    height: 0.625rem;
    border: solid #004867;
    border-width: 0 0.1875rem 0.1875rem 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}


