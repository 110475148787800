.Button {
    background-color: #066C98;
    /*background-color: #02898E;*/
    color: white;
    outline: none;
    border: none;
    text-align: left;
    padding: .5rem .75rem;
    font-weight: 600;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    height: 2.5rem;
}

.Button svg {
    margin-left: .5rem;
    color: white;
    font-size: 1rem;
}


.Button:hover {
    background-color: #065779
    /*background-color: #00686B*/

}

.Button:active {
    background-color: #066C98;
    /*background-color: #02898E;*/

}

.ButtonLink:active {
    color: white !important;
}

.Button.Danger {
    background-color: #dc3545;
}

.Button.Danger:hover {
	background-color: #bd2130;
}

.Button.Danger:active {
	  background-color: #dc3545;
}

.Button.Warning {
    background-color: #F5A523;
}

.Button.Warning:hover {
    background-color: #E58E00;
}

.Button.Warning:active {
    background-color: #F5A523;
}

.Button.Primary {
    background-color: #066C98;
}

.Button.Success {
    background-color: #28a745;
}

.Button.Success:hover {
    background-color: #1e7e34;
}

.Button.Success:active {
    background-color: #28a745;
}

.Button:disabled,
form:invalid .Button.GreyOnInvalid {
    background-color: grey;
}

.Button:disabled:hover,
form:invalid .Button.GreyOnInvalid:hover {
    background-color: grey;
}

.ButtonCoolgard {
    /*background-color: #066C98;*/
    background-color: #02898E;
    color: white;
    outline: none;
    border: none;
    text-align: left;
    padding: .5rem .75rem;
    font-weight: 600;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    height: 2.5rem;
}

.ButtonCoolgard svg {
    margin-left: .5rem;
    color: white;
    font-size: 1rem;
}


.ButtonCoolgard:hover {
    /*background-color: #065779*/
    background-color: #00686B

}

.ButtonCoolgard:active {
    /*background-color: #066C98;*/
    background-color: #02898E;

}

.ButtonCoolgardLink:active {
    color: white !important;
}

.ButtonCoolgard.Danger {
    background-color: #dc3545;
}

.ButtonCoolgard.Danger:hover {
    background-color: #bd2130;
}

.ButtonCoolgard.Danger:active {
    background-color: #dc3545;
}

.ButtonCoolgard.Warning {
    background-color: #F5A523;
}

.ButtonCoolgard.Warning:hover {
    background-color: #E58E00;
}

.ButtonCoolgard.Warning:active {
    background-color: #F5A523;
}

.ButtonCoolgard.Primary {
    background-color: #066C98;
}

.ButtonCoolgard.Success {
    background-color: #28a745;
}

.ButtonCoolgard.Success:hover {
    background-color: #1e7e34;
}

.ButtonCoolgard.Success:active {
    background-color: #28a745;
}

.ButtonCoolgard:disabled,
form:invalid .ButtonCoolgard.GreyOnInvalid {
    background-color: grey;
}

.ButtonCoolgard:disabled:hover,
form:invalid .ButtonCoolgard.GreyOnInvalid:hover {
    background-color: grey;
}

.Flex {
    flex: 1 1 auto;
}
