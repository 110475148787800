.SpinnerIcon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: .25rem solid currentColor;
  border-right: .25rem solid transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
}

@keyframes spinner-border {
  100% {
    transform: rotate(1turn);
  }
}
