.Toast {
    display: flex;
    flex-direction: column;
    margin: 1rem;
    background-color: white;
    box-shadow: 0 0.25rem 0.75rem rgba(0,0,0,.1);
    animation-name: fade;
    animation-timing-function: linear;
    -webkit-animation-fill-mode: forwards;
    color: white;
    border-radius: .25rem;
    cursor: pointer;
}

.Toast.ToastError {
    background-color: #F44336;
}

.Toast.ToastSuccess {
    background-color: #4CAF50;
}

.Toast.ToastWarning {
    background-color: #FF9800;
}

.Toast.ToastInfo {
    background-color: #2196F3;
}

.ToastFadeIn {
    animation-name: fade-in;
}

.ToastIcon {
    margin-right: 1rem;
    font-size: 1rem;
}

.ToastIcon svg {
    color: white;
}

.ToastContent {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: .25rem;
    background-color: inherit;
    position: relative;
}

.ToastBody {
    /*padding: 1.5rem 1rem;*/
    background-color: inherit;
    padding-right: 1rem;
}

.ToastTitle {
    margin-left: .75rem;
}

.ToastProgress {
    padding: .1rem;
    background-color: white;
    border-radius: .25rem;
    /*margin: .25rem;*/
    margin-top: 0;
    animation-name: progress;
    animation-timing-function: linear;
    -webkit-animation-fill-mode: forwards;
}

.ToastContainer {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 10000;
    width: 25rem;
}

.ToastClose {
    position: absolute;
    top: 0;
    right: 0;
}

.ToastClose svg {
    margin: .5rem;
    color: white;
}

.ToastInline {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 2px;
    padding: .5rem 1rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.ToastInline:last-of-type {
    margin-bottom: 0;
}

.InlineIconContainer {
    width: 2rem;
    display: inline-flex;
    justify-content: center;
}


@keyframes progress {
    from {
        width: 100%;
    }
    to {
        width: 0;
    }
}

@keyframes fade {
    0% { opacity: 0 }
    5% { opacity: 1 }
    95% { opacity: 1 }
    100% { opacity: 0 }
}

@keyframes fade-in {
    0% { opacity: 0 }
    5% { opacity: 1 }
    100% { opacity: 1 }
}

@media screen and (max-device-width: 400px) {
    .ToastContainer {
        min-width: 100%;
    }
}
