.Dropdown {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.Dropdown.Disabled {
    opacity: 0.7;
}

.Dropdown.Fill {
    height: 100%;
}

.Dropdown.Fill select,
.Dropdown.Fill .SelectContainer {
    height: 100%;
    outline: none;
    border: none;
}

.Dropdown.Fill .IconContainer {
    background-color: transparent;
    border: none;
}

.Dropdown.Inline {
    flex-direction: row;
    align-items: center;
}

.Dropdown.Inline label {
    margin: 0;
    white-space: nowrap;
}

.Dropdown select {
    min-height: 2.5rem;
    flex: auto;
    width: auto;
    min-width: 0;
    appearance: none;
    font-family: inherit;
    font-size: inherit;
    background-color: transparent;
    color: inherit;
    border: thin solid #DCDCDC;
    border-radius: 2px;
    padding: 0 3.5rem 0 .5rem;
    margin: 0 -3.5rem 0 0;
    cursor: pointer;
    outline: none;
}

.Dropdown select:disabled {
    opacity: 1;
    cursor: unset;
}

.Dropdown.HasBadge select {
    padding: 0 6.125rem 0 .5rem;
    margin: 0 -6.125rem 0 0;
}

.Dropdown label {
    margin-bottom: .5rem;
    text-align: left;
}

.Dropdown .SelectContainer {
    width: 100%;
    min-width: 0;
    display: flex;
    flex-direction: row-reverse;
    align-items: stretch;
    background: white;
    border-radius: 2px;
}

.Dropdown .IconContainer {
    width: 3.5rem;
    flex: none;
    background-color: #F2F2F2;
    border: thin solid transparent;
    border-left-color: #dcdcdc;
    border-radius: 0 2px 2px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Dropdown.Clear select {
    border: none;
}
.Dropdown.Clear select:focus {
    outline: none;
}

.Dropdown.Clear .IconContainer {
    background-color: transparent;
    border: none;
    transform: none;
    text-align: left;
}

.Dropdown .IconContainer svg {
    color: #004867;
}

.Badge {
    margin: 0 1rem;
    flex: none;
    align-self: center;
}
