.Dropdown {
    width: 100%;
    height: 100%;
    font-size: 1rem;
}

.DropdownToggle {
    height: 100%;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.DropdownToggleLeft {
    height: 100%;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: start;
    border-bottom: thin solid var(--border-color);

}

.DropdownToggleLeft:hover {
    cursor: pointer;
    background-color: #DDD;
}

.DropdownMenu {
    background-color: white;
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, .1);
    width: 18rem;
    /*width:auto;*/
    z-index: 1;
}

.DropdownItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: .5rem;
    margin: .5rem 0;
    padding-right: 2rem;
    cursor: pointer;
}

.DropdownItem:hover {
    background-color: lightgrey;
}

.DropdownItemIcon {
    width: 2rem;
    margin-right: .5rem;
}
