.PasswordReset {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: radial-gradient(#004867, #004766, #013B55);
}

.Panel {
    background-color: white;
    width: 400px;
    padding: 1.5rem;
    border-radius: 4px;
}

.Panel h3 {
    margin-top: 0;
    margin-bottom: 1rem;
}

.PasswordInput {
    margin-bottom: 1rem;
}

.SubmitButton {
    margin-top: 1.5rem;
    border-radius: 3px;
    height: 3rem;
}