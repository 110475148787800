.Page {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.Pages {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
}

.Content {
    flex-grow: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
}