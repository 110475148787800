
.BottomBar {
    width: 100%;
    max-height: 3.6875rem;
    height: 3.6875rem;
    display: flex;
    flex-shrink: 0;
    background: white;
    border-top: 1px solid #DDDDDD;
    overflow: hidden;
}

.Inside {
    height: 3.6875rem;
    width: 100%;
    align-items: center;
    text-align: left;
    margin-left: 2rem;
    flex-direction: row;
    display: flex;
}

.Icon {
    margin-left: 2rem;
    margin-right: 1rem;
    color: #1A7196;
    font-size: 1.3rem;

}

.IconExit {
    margin-left: 1rem;
    margin-right: 1rem;
    color: #1A7196;
    font-size: 1.3rem;

}

.UserSelected {
    flex: auto;
    font-weight: 600;
    font-size: 0.875rem;
    color: #002E41;
}

.block {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: auto;
    flex-grow: 0;
    border-left: 1px solid #DDDDDD;
    padding-right: 2rem;
    font-weight: 600;
    font-size: 0.875rem;
    color: #002E41;
}

.block:hover, .Exit:hover {
    cursor: pointer;
    background-color: #DDD;
}

.Exit {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: auto;
    flex-grow: 0;
    border-left: 1px solid #DDDDDD;
    font-weight: 600;
    color: #002E41;
}

