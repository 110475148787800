.Notifications {
    display: flex;
    flex-direction: column;
    max-width: 31.25rem;
    text-align: left;
    font-size: 0.875rem;
    max-height: 50vh;
    overflow-y: scroll;
}

.Notification {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    border-bottom: thin solid #ddd;
}

.NotificationIconContainer {
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-height: 2.125rem;
    min-width: 2.125rem;
    margin-right: 1rem;
}

.NotificationTime {
    flex-shrink: 0;
    margin-left: 1rem;
    color: #607A84
}

.NotificationText {
    display: flex;
    flex-direction: row;
}

.NotificationName {
    font-weight: 600;
}

.Notification:last-of-type {
    border-bottom: none;
}

.Flex {
    flex: 1 1 auto;
}