.Popup {
    min-width: 25.875rem;
}

.PopupItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    border-bottom: thin solid #ddd;
    cursor: pointer;
    font-size: .875rem;
}

.PopupItem:last-of-type {
    border-bottom: none;
}

.PopupItem:hover {
    background-color: #ddd;
}

.PopupItem span {
    margin-bottom: -2px;
}

.PopupItem svg {
    height: 100%;
    margin-right: .75rem;
}

.PopupHeader {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 1rem;
    border-bottom: thin solid #ddd;
    background-color: #F8F8F8;
    font-size: 1rem;
}

.PopupHeader {
    font-weight: 600;
}

.PopupHeader svg {
    margin-right: 1rem;
    font-size: 2.3125rem;
}

.IconContainer {
    width: 2rem;
}