.NewClientButton {
    padding: .75rem;
    height: 100%;
    display: inline-flex;
    align-items: center;
    width: 15rem;
    border-left: thin solid var(--border-color);
}

.NewClientButton button {
    width: 100%;
}

.SearchInput {
    width: 15rem;
    border-left: thin solid var(--border-color);
}

.Select {
    font-size: 1rem;
    width: 15rem;
    border-left: thin solid var(--border-color);
    padding-left: 1rem;
}

.SubmitButton {
    min-width: 15rem;
}

.Table {
    flex: auto;
    height: auto;
    display: flex;
    flex-direction: column;
}
