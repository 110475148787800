/*Full Panel*/
.Panel {
    width: 17.5625rem;
    height: auto;
    font-weight: 600;
    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 0.1875rem 0.375rem #00000029;
    border: 1px solid #DDDDDD;
    opacity: 1;
    text-align: left;
    margin: 2rem;


}

.LowPressureWarning {
    font-size: 0.875rem;
    color: #004867;
    margin: auto;
    display: block;
    padding: 16px;
}

.midBorder {
    border-top: 1px solid #DDDDDD;
    margin-left: 1rem;
    margin-right: 1rem;
}

.Message {
    font-size: 0.8125rem;
    color: #004867;
    padding: 0.6rem 1rem;
}

.Dot {
    height: 0.5rem;
    width: 0.5rem;
    background-color: #F7AC1E;
    border-radius: 50%;
    display: inline-block;
    margin-right: 0.5rem;
}

.Warning {
    background-color: #F7AC1E;
}

.Active {
    background-color: #80D835;
}

.Failure {
    background-color: #D14040;
}
