.Login {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /*background-image: radial-gradient(#00686B, #00686B, #00686B);*/
  /*background-image: radial-gradient(#004867, #004766, #013B55);*/
  font-size: 1rem;
  justify-content: center;
  align-items: center;
}

.Flex {
  flex: 1 1 auto;
}

.LoginFooter {
  /*background-color: #056C98;*/
  /*background-color: #02898E;*/
  width: 100%;
  height: 4.375rem;
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  color: white;
}

.LoginFooter img {
  height: 100%;
  min-height: 1.5rem;
}

.LoginFooter button {
  color: white;
}

.LoginFooter button:first-of-type {
  margin-right: 1rem;
}

.PanelHeader {
  padding: 1.5rem;
  background-color: #F8F8F8;
  font-size: 1.1rem;
}

.FormDetails, .FormSubmit {
  display: flex;
  flex-direction: column;
}

.Form {
  display: flex;
  flex-direction: column;
}

.FormButtons {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
}

.PanelFooter {
  padding: 1.5rem;
}

.Container {
  width: 100%;
  max-width: 25rem;
  margin: 1rem;
}

.Panel {
  display: flex;
  text-align: left;
  border-radius: 4px;
  flex-direction: column;
  background-color: white;
}

.Icon {
  margin-bottom: 1rem;
}

.Welcome {
  font-weight: 600;
}

.Instructions {
  margin-top: .5rem;
}

.Email, .Password, .RecoveryCode {
  outline: none;
  border-style: solid;
  border: none;
  border-width: 1px;
  border: 1px solid lightgrey;
  padding: 1.5rem;
  border-left: 0;
  border-right: 0;
  font-size: 1rem;
  font-family: inherit;
}

.Email {
  border-bottom: none;
}

.Email:-webkit-autofill::first-line {
  font-size: 1rem;
  font-family: 'Nunito Sans', sans-serif;
}

.ResetEmail:-webkit-autofill::first-line {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
}

.TOTPContainer {
  display: flex;
  flex-direction: column;
  background-color: #F8F8F8;
}
.TOTPContainer.NoRecoveryCodes {
  padding-bottom: 1.5rem;
  border-bottom: 1px solid lightgray;
}
.TOTP {
  display: flex;
  justify-content: center;
}
.TOTP :global(.a-reactPinField__input) {
  font-family: inherit;
  border-radius: 2px;
  box-shadow: none; /* Override Firefox invalid styling */
  text-align: center;
  font-size: 1.67rem;
  font-variant-numeric: tabular-nums;
  width: 1ch;
  padding: 1.1rem;
  box-sizing: content-box;
  background: #fff;
  border: 1px solid lightgrey;
}
.TOTP :global(.a-reactPinField__input) + :global(.a-reactPinField__input) {
  margin-left: 0.33rem;
}
.TOTP :global(.a-reactPinField__input) + :global(.a-reactPinField__input):nth-of-type(4) {
  margin-left: 1rem;
}
.TOTP :global(.a-reactPinField__input):focus {
  outline: none;
  border-color: #02898E;
}

.UseARecoveryCode {
  display: block;
  padding: 1rem 1.5rem;
  background-color: #F8F8F8;
  border-bottom: 1px solid lightgrey;
  text-decoration: none;
  color: #0086BE;
}
.UseARecoveryCode .label {
  color: #002E41;
}
.UseARecoveryCode:hover .link {
  text-decoration: underline;
}

@keyframes invalid-code {
  0% { transform: translateX(  0  ); }
  8% { transform: translateX(-10px); }
  25% { transform: translateX( 10px); }
  41% { transform: translateX(-10px); }
  58% { transform: translateX( 10px); }
  75% { transform: translateX( -5px); }
  92% { transform: translateX(  5px); }
  100% { transform: translateX(  0  ); }
}

.Remember2FA {
  font-weight: normal;
  display: flex;
  align-items: center;
}
.Remember2FA input {
  margin: 0.2rem 0.6rem 0.2rem 0;
}

.Button {
  /*background-color: #066C98;*/
  outline: none;
  border: none;
  border-radius: 3px;
  height: 3rem;
  color: white;
  font-size: inherit;
}

.Login .Button:active {
  outline: red;
}

.Button:hover {
  /*background-color: #065779*/
}

.Button:active {
  /*background-color: #066C98;*/
}

.RememberMe {
  margin: 0 1.5rem;
  margin-top: 1.5rem;
}

.ForgotModal {
  width: 35rem;
}

.Logo {
  margin-bottom: 2rem;
}

.Logo svg {
  fill: white;
}

.PulsePresence {
  width: 22em;
  height: 3em;
}
