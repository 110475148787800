.Modal {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: white;
    opacity: 0;
    transition: opacity .2s linear;
    border-radius: 3px;
    width: 40rem;
    box-shadow: 0 0.25rem 0.75rem rgba(0,0,0,.1);
    max-width: 100vw;
    max-height: 100vh;
}

.Modal > form {
    display: contents;
    flex-direction: inherit;
}

.Modal.Opened {
    opacity: 1;
}

/* .CloseButton {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-50%) translateX(50%);
    background-color: #066C98;
    padding: .5rem;
    cursor: pointer;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    color: white;
    display: inline-flex;
    z-index: 3;
} */

.CloseButton {
    /* width: 100% !important;
		height: 100% !important; */
    cursor: pointer;
}

.Header {
    flex: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.5rem;
    color: #002E41;
    font-weight: 600;
    border-bottom: thin solid var(--border-color);
}

.Modal h3 {
    margin: 0;
    font-weight: 600;
}

.Body {
    display: flex;
    flex-direction: inherit;
    flex: 0 1 auto;
    overflow-y: scroll;
    border-bottom: thin solid var(--border-color);
}

.Body > .Section {
    padding-right: 1.25rem;
}

.Footer {
    flex: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.5rem;
    border-top: thin solid #DDD;
}

.Section {
    padding: 1.5rem;
    border-bottom: thin solid var(--border-color);
}

.Section:last-of-type {
    border-bottom: none;
}
