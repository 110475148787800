:global(body.board) {
    overflow: auto;
}
:global(body.board #root) {
    height: unset;
    min-height: 100%;
}

.CheckInBoard {
    text-align: left;
    flex: auto;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    min-width: 0;
}

.Status {
    margin: 0 1rem;
    padding: 1rem .75rem;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    border-bottom: 1px solid #ededed;

    position: sticky;
    top: 0;
    background: white;
}

.CheckReset {
    cursor: pointer;
    flex: none;
    appearance: none;
    border: none;
    outline: none;
    background: none;
    margin: -1rem -1rem -1rem auto;
    padding: 1rem;
    font: inherit;
    color: #0099ff;
    text-decoration: underline;
}
.CheckReset:focus {
    background: #f8f8f8;
}
.CheckReset:hover {
    background: #f5f5f5;
}
.CheckReset:active {
    background: #ededed;
}

.SiteSelector {
    margin: 0 1rem;
    padding: 1rem 0;
    border-bottom: 1px solid #ededed;
}

.UserGrid {
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;
    padding: 0.75rem 0;
    margin: 0 0.75rem;
}

.User {
    background: #fcfcfc;
    border: 1px solid #dcdcdc;
    width: 24rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0.25rem;
    border-radius: 2px;
}

.UserChecked {
    border: 1px solid #0099ff;
}

.UserImage {
    width: 3.33rem;
    height: 3.33rem;
    object-fit: cover;
    flex: none;
    margin: 0.33rem 0.33rem 0.33rem 0;
    border-radius: 2px;
}

.UserDetails {
    margin: 0.33rem 0.5rem;
    flex: auto;
    display: flex;
    flex-direction: column;
}

.UserName {
    font-size: 16px;
    font-weight: 600;
}

.UserCheckbox {
    align-self: stretch;
    display: flex;
    align-items: center;
}
.UserCheckbox input {
    margin: 0.33rem;
}

.Phone {
    height: 90%;
    display: flex;
    flex-direction: column;
}

.UserPhone {
    display: flex;
    flex: auto;
    padding: 0 1rem;
    align-self: stretch;
    align-items: center;
}

.UserPhone:focus {
    background: #f8f8f8;
}
.UserPhone:hover {
    background: #f5f5f5;
}
.UserPhone:active {
    background: #ededed;
}

.Header {
    flex: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 18px 0;
    margin: 0 18px;
    min-height: 72px;
    border-bottom: 1px solid #ededed;
}

.HeaderLogo {
    flex: none;
    width: 147px;
    height: 24px;
    position: relative;
}

.HeaderLogoPulse {
    width: 110px;
    height: 30px;
}

.HeaderLogout {
    flex: none;
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    appearance: none;
    border: none;
    outline: none;
    background: none;
    margin: -18px -18px -18px auto;
    padding: 21px 18px 15px 18px;
    font: inherit;
    color: inherit;
    font-size: 19px;
    font-weight: 600;
}

.HeaderLogoutIcon {
    flex: none;
    margin: 0 10px 2px 0;
    font-size: 22px;
}

.LastUpdated {
    margin: 0 1rem;
    padding: 1rem 0;
    font-size: 14px;
    border-top: 1px solid #ededed;

    position: sticky;
    bottom: 0;
    background: white;
}

.Spinner {
    align-self: center;
    margin: 1rem;
}

.TabBar {
    display:flex;
    flex-direction: row;
    margin:0 1rem;
    border-bottom: 1px solid #ededed;}

.Tab {
    padding: .90rem .75rem;
    height: 100%;
    display: inline-flex;
    align-items: center;
    font-size: 1rem;
    border-bottom:  solid #FFFFFF;
    cursor:pointer;
}

.Tab.Selected {
    border-bottom:  solid #0B686B;
    cursor:initial;
}

.Icon {
    font-size: 44.5px;
    border: 1px solid #ededed;
}
