.UserDetails {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.PasswordInput {
    margin-bottom: 1rem;
}

.SubmitButton {
    min-width: 10rem;
}

.UserIconContainer {
    position: relative;
}

.Badge {
    position: absolute;
    border: thin solid white;
    height: 1rem;
    width: 1rem;
    right: 1rem;
    top: 0;
    transform: translateY(-30%);
    text-align: center;
    vertical-align: top;
    border-radius: 50%;
}

.Badge.Connected {
    background-color: #7AE320;
}

.Badge.Disconnected {
    background-color: #D14040;
}

.UserDetailsModal {
    width: 30rem;
}
