@import "assets/fonts/fonts.css";
@import "themes/tooltips.css";
@import "assets/css/flex-layout.css";
@import "themes/globals.css";

*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    height: 100%;
}

body {
    margin: 0;
    font-family: 'Nunito Sans', sans-serif;
    /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
    /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
    /*  sans-serif;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    color: #002E41;
    font-size: 1rem;
    overflow: hidden;
}
body.checkin {
    overflow: auto;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div#root {
    height: 100%;
}

button, input[type="button"] {
    font-family: inherit;
    font-weight: normal;
    cursor: pointer;
}

label {
    font-weight: 600;
    color: #002E41
}

html *::-webkit-scrollbar {
    width: 0.25rem;
    height: 0.4rem;
    background-color: #F5F5F5;
}

html *::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #195975;
    box-shadow: inset 0 0 6px #195975;
    background-color: #195975;
}

* {
    scrollbar-width: thin;
    scrollbar-color: #195975 #F5F5F5;
}

.layout.grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 1rem;
}

.layout.grid.align-end {
    align-items: end;
}

svg {
    color: #195975
}
