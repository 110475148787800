.WidgetView {
  width: 100%;

  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.Vertical {
  display: flex;
  /*height: 100%;*/
  width: 100%;
  flex-direction: column;
}
.Horizontal {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
}

.BottomBar {
  display: flex;
}
/*.TableComponent {*/
/*  padding-bottom:3.6875rem;*/

/*}*/
/*.NewUser {*/
/*  flex: auto;*/

/*}*/
