.rdt_TableCol_Sortable {
    font-size: 1rem;
    color: #002E41 !important;
}

.rdt_TableCol {
    border-left: 1px solid #DDDDDD;

}

/*Pagination*/
.rdt_Table {
    flex:auto;
    height:auto;
}

.rdt_TableBody {
    overflow-y: scroll;
     /*max-height: calc(100vh - 3.5rem - (3.6875rem*2));*/
    flex-shrink: 1;
    flex-grow: 1;
    flex-basis: 0;
    overflow-x: hidden;
}

.rdt_TableHeadRow {
    padding-right:0.25rem;
    z-index: 9998;
    -webkit-box-shadow: 0 4px 6px -6px #222;
    -moz-box-shadow: 0 4px 6px -6px #222;
    box-shadow: 0 4px 6px -6px #222;

}
.rdt_TableRow {
    height: 2.5625rem;
    z-index: auto;
    /*cursor: pointer;*/
}

.rdt_Pagination {
    flex-grow: 0 !important;
}

.__rdt_custom_sort_icon__ {
    color: #1A7196 !important;
    font-size: 1rem;
    position: absolute;
    right: 1rem;
    display: inline-block;
}
.__rdt_custom_sort_icon__{

}

.rdt_TableCell {
    border-left: 1px solid #DDDDDD;
    background-color: #F1F7FB58;
}


.rdt_TableRow:last-of-type {
    border-bottom: 1px solid #DDDDDD;
}

/* Status */
.Active {
    height: 0.5rem;
    width: 0.5rem;
    background-color: #80D835;
    border-radius: 50%;
    display: inline-block;
    margin-right: 0.5rem;
}

.Warning, .LowPressure{
    height: 0.5rem;
    width: 0.5rem;
    background-color: #F7AC1E;
    border-radius: 50%;
    display: inline-block;
    margin-right: 0.5rem;
}

.Failure {
    height: 0.5rem;
    width: 0.5rem;
    background-color: #D14040;
    border-radius: 50%;
    display: inline-block;
    margin-right: 0.5rem;
}
/*      */
.IconSolid {
    opacity:1 !important;
}
