.Main {
    text-align: center;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
	align-items: flex-start;
}

.Page {
    height: 100%;
    position: relative;
    overflow-x: scroll;
	display: inline-flex;
    flex-direction: row;
    flex: 1 1 auto;
}

@media (min-width: 1000px) {
    .Page {
        overflow-x: auto;
    }
}