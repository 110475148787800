.DateTime {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    position: relative;
}

.DateTime > label {
    flex: none;
    font-weight: 600;
    margin-bottom: .5rem;
    color: #002E41;
    text-align: left;
}

.DateTimeWrapper {
    display: flex;
    flex-direction: row;
    flex: none;
    align-items: stretch;
}

.DateTimeWrapper > input {
    font: inherit;
    color: #373737;
    border: 1px solid #DCDCDC;
    outline: none;
    padding: 0 .5rem;
    min-height: 2.5rem;
    background: white;
}

.DateTimeWrapper > input[type=date] {
    flex: auto;
    border-right: none;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}

.DateTimeWrapper > input[type=time] {
    flex: auto;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}
