.Input input {
    border: none;
    appearance: none;
    border: thin solid #DCDCDC;
    border-radius: 2px;
    height: 2.5rem;
    font-size: 1rem;
    font-family: inherit;
    padding-left: .5rem;
    color: #373737;
    width: 100%;
}

.PasswordInput {
    border-bottom: thick solid #d60707;
}

.Label {
    color: #002E41;
    font-weight: 600;
    margin-bottom: .5rem;
    color: #002E41;
    text-align: left;
}

.Input {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    position: relative;
}

.Input input:focus {
    outline: thin solid #004867;
}

.Fill, .Fill input {
    height: 100%;
    border: none;
    outline: none;
}

.Fill input:focus {
    outline: none;
}

.Fill > div {
    height: 100%;
}

.SearchIcon {
    margin: 0 1rem;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
}

.NoPassword {
    display: none;
}
