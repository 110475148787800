.MenuItemBatbooth {
  width: 2.813rem;
  height: 2.813rem;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: start;
  cursor: pointer;
  z-index: 9999;
  position: relative;
}

.MenuItemBatbooth:hover {
  background: #1A7196;
  /*background: #038F93;*/
  /*background: inherit;*/
}

.MenuItemCoolgard {
  width: 2.813rem;
  height: 2.813rem;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: start;
  cursor: pointer;
  z-index: 9999;
  position: relative;
}

.MenuItemCoolgard:hover {
  /*background: #1A7196;*/
  background: #038F93;
  /*background: inherit;*/
}

.Icon {
  width: 2.813rem;
  height: 2.813rem;
  align-items: center;
  position: relative;
  display: inline-block;
  float: left;
}

/*.Icon:hover {*/
/*  background: #1A7196;*/
/*}*/

.Icon .Container {
  width: 2.813rem;
  height: 2.813rem;
  align-items: center;
  justify-content: center;
  display: flex;
}

.Badge {
  color: white;
  position: absolute;
  background: #D14040;
  height: 1.3rem;
  width: 1.3rem;
  right: 0.3rem;
  top: 0;
  text-align: center;
  vertical-align: top;
  border-radius: 50%;
  border: 1px solid #003D58;
  font-size: .8rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.SelectedBatbooth {
  background-color: #1A7196;
  /*background-color: #038F93;*/
}

.SelectedCoolgard {
  /*background-color: #1A7196;*/
  background-color: #038F93;
}

.TextContent {
  position: absolute;
  left: 100%;
  width: 0;
  overflow: hidden;
}

.TextContent span {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.75em;
  color: white;
  padding-left: 1em;
  vertical-align: middle;
  width: 100%;
  height: 2.813rem;
  /*background-color: #1A7196;*/
  /*background-color: #038F93;*/
  white-space: nowrap;

}

.MenuItemBatbooth:hover .TextContent {
  display: flex;
  width: 11em;
  transition: width 0.2s ease-out;
}

.MenuItemCoolgard:hover .TextContent {
  display: flex;
  width: 11em;
  transition: width 0.2s ease-out;
}
