.Footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: thin solid var(--border-color);
    flex-shrink: 0;
}

.Count {
    display: flex;
    padding: 1rem;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
}

.Count > div:first-of-type {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    width: auto;
    padding: .5rem;
    margin-right: 1rem;
}

.Records {
    font-size: 0.875rem;
    font-weight: normal;
}

.FooterAction {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    padding: 1rem;
    height: 100%;
}

.FooterAction:hover {
    background-color: lightgrey;
}

.FooterAction svg {
    font-size: 1.1rem;
    margin-right: 1rem;
}

.FooterItem {
    border-left: thin solid var(--border-color);
    width: auto;
    height: 100%;
}

.FooterItem button {
    width: 100%;
}
